import './App.css';
import React, { useState, useEffect } from 'react';
import DocumentMeta from 'react-document-meta';
import StripeCheckout from 'react-stripe-checkout';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import MessageIcon from '@material-ui/icons/Message';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import SendIcon from '@material-ui/icons/Send';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const moment = require('moment-timezone');
const axios = require('axios');

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: '100vh'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function App() {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [linkValid, setLinkValid] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(true);

  const [accountId, setAccountId] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('#e6e6e6');
  const [bannerImage, setBannerImage] = useState('');
  const [businessName, setBusinessName] = useState('Online Voucher');
  const [businessPhone, setBusinessPhone] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [currency, setCurrency] = useState('$');
  const [checkoutCurrency, setCheckoutCurrency] = useState('AUD');
  const [onlineBookingsEnabled, setOnlineBookingsEnabled] = useState(false);
  const [onlineBookingsUrl, setOnlineBookingsUrl] = useState('');

  const [presetVouchers, setPresetVouchers] = useState([]);
  const [allowPresetVouchers, setAllowPresetVouchers] = useState(false);
  const [allowCustomVouchers, setAllowCustomVouchers] = useState(false);
  const [minimumValue, setMinimumValue] = useState(0);
  const [maximumValue, setMaximumValue] = useState(0);
  const [currentVoucherNumber, setCurrentVoucherNumber] = useState('');
  const [customVoucherExpiresIn, setCustomVoucherExpiresIn] = useState('3');
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showSummary, setShowSummary] = useState(false);

  // voucher info
  const [fromName, setFromName] = useState('');
  const [fromEmail, setFromEmail] = useState('');
  const [recipients, setRecipients] = useState([{
    toName: '',
    toEmail: '',
    message: '',
    selectedVoucherId: '',
    voucherValue: 0,
    voucherPrice: 0,
    voucherExpires: moment().format('DD/MM/YYYY'),
    expiresIn: '0',
    voucherDescription: ''
  }]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('business')) {
      axios.post('https://app.clientdiary.com/methods/getBusinessDetailsOnlineVouchers', [urlParams.get('business')])
      .then(function (response) {
        const result = response.data;
        
        if (result.available === false || (!result.testAccount && (result.subscription === undefined || (result.subscription.status !== 'active' && result.subscription.status !== 'trialing' && result.subscription.status !== 'past_due')))) {
          setLinkValid(false);
          setLoading(false);
        } else {
          const sortAlphaNum = (a, b) => a.description.localeCompare(b.description, 'en', { numeric: true });
          let sortedPresetVouchers = result.presetVouchers.sort(sortAlphaNum);

          setAccountId(result.accountId);
          setBackgroundImage(result.backgroundImage === undefined ? '' : result.backgroundImage);
          setBackgroundColor(result.backgroundColor === undefined ? '#e6e6e6' : result.backgroundColor);
          setBannerImage(result.bannerImage === undefined ? '' : result.bannerImage);
          setBusinessName(result.businessName);
          setBusinessPhone(result.businessPhone);
          setLogoUrl(result.logoUrl);
          setPresetVouchers(sortedPresetVouchers);
          setAllowPresetVouchers(result.allowPresetVouchers);
          setAllowCustomVouchers(result.allowCustomVouchers);
          setMinimumValue(result.minimumValue);
          setMaximumValue(result.maximumValue);
          setCurrentVoucherNumber(result.currentVoucherNumber);
          setCustomVoucherExpiresIn(result.customVoucherExpiresIn);
          setTermsAndConditions(result.termsAndConditions);
          setCurrency(result.currency);
          setCheckoutCurrency(result.checkoutCurrency);
          setOnlineBookingsEnabled(result.onlineBookingsEnabled);
          setOnlineBookingsUrl(result.onlineBookingsUrl);

          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    } else {
      setLinkValid(false);
      setLoading(false);
    }
  }, []);

  const getComputedStyle = () => {
    let mainStyle = {
      flex: 1
    };

    let backgroundImageStyle = '';
    if (backgroundImage !== '') {
      backgroundImageStyle = 'url(' + backgroundImage + ')';
    }

    if (backgroundImage.includes('Default')) {
      mainStyle.backgroundColor = backgroundColor;
    } else if (backgroundImage !== '') {
      mainStyle.backgroundImage = backgroundImageStyle;
      mainStyle.backgroundRepeat = 'repeat';
    }

    return mainStyle
  }

  const getAuthor = () => {
    let author = '';
    
    if (backgroundImage !== '') {
      if (backgroundImage.includes('BSGStudio')) {
        author = 'BSGStudio';
      } else if (backgroundImage.includes('freedesignfile')) {
        author = 'freedesignfile';
      } else if (backgroundImage.includes('peecheey')) {
        author = 'peecheey';
      } else if (backgroundImage.includes('webdesignhot')) {
        author = 'webdesignhot';
      }
    }

    return author
  }

  const handleAddNewRecipient = () => {
    let tempRecipients = [...recipients];
    tempRecipients.push({
      toName: '',
      toEmail: '',
      message: '',
      selectedVoucherId: '',
      voucherValue: 0,
      voucherPrice: 0,
      voucherExpires: moment().format('DD/MM/YYYY'),
      expiresIn: '0',
      voucherDescription: ''
    });

    setShowSummary(false);
    setRecipients(tempRecipients);
  }

  const handleClickContinueToSummary = () => {
    if (recipients.find(r => r.toName === '') !== undefined) {
      setAlertDialogOpen(true);
      setAlertMessage('Recipient must have a name.');

      return;
    }

    if (recipients.find(r => r.selectedVoucherId === '') !== undefined) {
      setAlertDialogOpen(true);
      setAlertMessage('Please select voucher.');

      return;
    }

    if (recipients.find(r => r.selectedVoucherId === 'custom' && (Number(r.voucherValue) < Number(minimumValue) || Number(r.voucherValue) > Number(maximumValue))) !== undefined) {
      setAlertDialogOpen(true);
      setAlertMessage('Voucher value must be between ' + currency + minimumValue + ' and ' + currency + maximumValue);

      return;
    }

    setShowSummary(true);
  }

  const handleChangeToName = (index, e) => {
    let tempRecipients = [...recipients];
    tempRecipients[index].toName = e.target.value;

    setShowSummary(false);
    setRecipients(tempRecipients);
  }

  const handleChangeToEmail = (index, e) => {
    let tempRecipients = [...recipients];
    tempRecipients[index].toEmail = e.target.value;

    setShowSummary(false);
    setRecipients(tempRecipients);
  }

  const handleChangeMessage = (index, e) => {
    let tempRecipients = [...recipients];
    tempRecipients[index].message = e.target.value;

    setShowSummary(false);
    setRecipients(tempRecipients);
  }

  const handleChangeSelectedVoucher = (index, e) => {
    let tempRecipients = [...recipients];
    tempRecipients[index].selectedVoucherId = e.target.value;

    if (e.target.value !== 'custom') {
      let selectedPreset = presetVouchers.find(voucher => voucher._id === e.target.value);
      tempRecipients[index].voucherValue = (selectedPreset.value / 100).toFixed(2);
      tempRecipients[index].voucherPrice = (selectedPreset.price / 100).toFixed(2);
      tempRecipients[index].voucherExpires = (selectedPreset.expiresIn === '0' ? 'Never expires' : moment().add(Number(selectedPreset.expiresIn), 'month').format('DD/MM/YYYY'));
      tempRecipients[index].expiresIn = selectedPreset.expiresIn;
      tempRecipients[index].voucherDescription = selectedPreset.description;
    } else {
      tempRecipients[index].voucherExpires = (customVoucherExpiresIn === '0' ? 'Never expires' : moment().add(Number(customVoucherExpiresIn), 'month').format('DD/MM/YYYY'));
      tempRecipients[index].expiresIn = customVoucherExpiresIn;
      tempRecipients[index].voucherDescription = 'Custom Voucher';
    }

    setShowSummary(false);
    setRecipients(tempRecipients);
  }

  const handleChangeVoucherValue = (index, e) => {
    let tempRecipients = [...recipients];
    tempRecipients[index].voucherValue = e.target.value;
    tempRecipients[index].voucherPrice = e.target.value;

    setShowSummary(false);
    setRecipients(tempRecipients);
  }

  const handleRemoveRecipient = (index) => {
    let tempRecipients = [...recipients];
    tempRecipients.splice(index, 1);

    setShowSummary(false);
    setRecipients(tempRecipients);
  }

  const onToken = (token) => {
    let amountToPay = recipients.reduce((prev, cur) => prev + Number(cur.voucherPrice), 0) * 100;
    let sender = {
      name: fromName,
      email: fromEmail
    };

    setSubmitting(true);

    axios.post('https://app.clientdiary.com/methods/onlineVouchersPayment', [amountToPay, token, sender, recipients, accountId])
    .then(function (response) {
      const result = response.data;
      if (result.type === 'error') {
        setSubmitting(false);
        setSubmitted(true);
        setSuccess(false);
      } else {
        axios.post('https://app.clientdiary.com/methods/onlineVouchersCreate', [sender, recipients, accountId])
        .then(function (response1) {
          setSubmitting(false);
          setSubmitted(true);
          setSuccess(true);
        })
        .catch(function (error) {
          console.log(error);
          setSubmitting(false);
          setSubmitted(true);
          setSuccess(false);
        });
      }
    })
    .catch(function (error) {
      console.log(error);
      setSubmitting(false);
      setSubmitted(true);
      setSuccess(false);
    });
  }

  const renderContent = () => {
    if (!linkValid) {
      return (
        <Grid item>
          <Card variant="outlined" style={{ margin: 10 }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>Online Voucher link is incorrect</Typography>
              <Typography variant="h6" gutterBottom>What should I do?</Typography>
              <Typography variant="body1" gutterBottom>♦ Please contact the salon and double check the online voucher link.</Typography>
              <br />
              <Typography variant="body2">Thank you,</Typography>
              <br />
              <Typography variant="body2"><strong><i>{businessName}</i></strong></Typography>
            </CardContent>
          </Card>
        </Grid>
      )
    } else {
      if (submitted) {
        return (
          <Grid item>
            <Card variant="outlined" style={{ margin: 10 }}>
              { success ?
                <CardContent>
                  <Typography variant="h5" gutterBottom>Thanks {fromName} for using our online vouchers service.</Typography>
                  <Typography variant="h6" gutterBottom>What's next?</Typography>
                  <Typography variant="body1" gutterBottom>♦ We will send the voucher to the recipient's address with your message.</Typography>
                  <Typography variant="body1" gutterBottom>♦ If no recipient's address is nominated, the voucher will be sent to your email address.</Typography>
                  <br />
                  <Typography variant="body2">Thank you and we look forward to seeing you.</Typography>
                  <br />
                  <Typography variant="body2"><strong><i>{businessName}</i></strong></Typography>
                  <Typography variant="body2"><strong><i>{businessPhone}</i></strong></Typography>
                  <br />
                  <Typography variant="subtitle2"><i>You can now safely close this page.</i></Typography>
                  <br />
                  <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
                    Buy another voucher
                  </Button>
                </CardContent>
                :
                <CardContent>
                  <Typography variant="h5" gutterBottom>Thanks {fromName} for using online booking.</Typography>
                  <Typography variant="h6" gutterBottom style={{ color: 'red' }}><strong>Unfortunately, an error has occured.</strong></Typography>
                  <Typography variant="body1" gutterBottom>♦ Please contact us and we will help you with your purchase.</Typography>
                  <br />
                  <Typography variant="body2">Thank you and we look forward to hearing from you.</Typography>
                  <br />
                  <Typography variant="body2"><strong><i>{businessName}</i></strong></Typography>
                  <Typography variant="body2"><strong><i>{businessPhone}</i></strong></Typography>
                  <br />
                  <Typography variant="subtitle2"><i>You can now safely close this page.</i></Typography>
                  <br />
                  <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
                    Buy another voucher
                  </Button>
                </CardContent>
              }
            </Card>
          </Grid>
        )
      } else {
        return (
          <>
            {onlineBookingsEnabled ?
              <Grid item style={{ width: '100%' }}>
                <Card variant="outlined" style={{ margin: 10, backgroundColor: 'rgb(242, 245, 248)' }}>
                  <CardContent>
                    <div>{businessName} also offers you to book appointments online.</div>
                    <Button variant="contained" color="primary" style={{ marginTop: 10 }} href={"https://booking.clientdiary.com/?bookingsUrl=" + onlineBookingsUrl}>
                      Book Now
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              :
              null
            }
            <Grid item style={{ width: '100%' }}>
              <Card variant="outlined" style={{ margin: 10, backgroundColor: 'rgb(242, 245, 248)' }}>
                <CardHeader title="Sender Details" />
                <CardContent style={{ paddingTop: 0 }}>
                  <div className="row">
                    <div className="col-xs-12 col-md-4 col-lg-4">
                      <Card style={{ margin: 10 }} variant="outlined">
                        <CardHeader title="From" />
                        <CardContent style={{ paddingTop: 0 }}>
                          <FormControl variant="outlined" fullWidth margin="normal">
                            <InputLabel>Name</InputLabel>
                            <OutlinedInput
                              value={fromName}
                              onChange={(e) => {
                                setShowSummary(false);
                                setFromName(e.target.value);
                              }}
                              labelWidth={50}
                              startAdornment={
                                <InputAdornment position="start">
                                  <PersonIcon />
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          <FormControl variant="outlined" fullWidth margin="normal">
                            <InputLabel>Email address</InputLabel>
                            <OutlinedInput
                              value={fromEmail}
                              onChange={(e) => {
                                setShowSummary(false);
                                setFromEmail(e.target.value);
                              }}
                              labelWidth={110}
                              startAdornment={
                                <InputAdornment position="start">
                                  <EmailIcon />
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <Card variant="outlined" style={{ margin: 10, backgroundColor: 'rgb(242, 245, 248)' }}>
                <CardHeader title="Voucher & Recipient Details" />
                <CardContent style={{ paddingTop: 0 }}>
                  <div className="row">
                    {renderRecipients()}
                    <div className="col-xs-12 col-md-4 col-lg-4">
                      <Card style={{ margin: 10 }} variant="outlined">
                        <CardContent>
                          <div className="center-xs">
                            <Button color="secondary" onClick={handleAddNewRecipient}>
                              Add new recipient
                            </Button>
                          </div>
                          <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                          <div className="center-xs">
                            <Button variant="contained" color="primary" onClick={handleClickContinueToSummary}>
                              Continue to summary
                            </Button>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <Card variant="outlined" style={{ margin: 10, backgroundColor: 'rgb(242, 245, 248)' }}>
                <CardHeader title="Summary" />
                <CardContent style={{ paddingTop: 0 }}>
                  {showSummary === false ?
                    <p>Please complete all above sections.</p>
                    :
                    <div className="row">
                      <div className="col-xs-12 col-md-6 col-lg-6">
                        <Paper variant="outlined" style={{ padding: 15 }}>
                          <Typography variant="h5">Sender Details</Typography>
                          <List disablePadding>
                            <ListItem>
                              <ListItemIcon>
                                <SendIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={fromName}
                                secondary={fromEmail}
                              />
                            </ListItem>
                          </List>
                          <Divider />
                          <Typography variant="h5" style={{ marginTop: 10 }}>Recipient Details</Typography>
                          {renderRecipientsSummary()}
                        </Paper>
                      </div>
                      <div className="col-xs-12 col-md-6 col-lg-6">
                        {termsAndConditions !== undefined || termsAndConditions !== '' ?
                          <Card variant="outlined">
                            <CardHeader title="Terms & Conditions" />
                            <CardContent>
                              <div style={{ whiteSpace: 'pre-line' }}>
                                {termsAndConditions}
                              </div>
                            </CardContent>
                          </Card>
                          :
                          null
                        }
                        <div className="center-xs" style={{ marginTop: 15 }}>
                          <StripeCheckout
                            token={onToken}
                            stripeKey="pk_live_d7KO18UnRkBTgKHDqZt67xcY"
                            // stripeKey="pk_test_tY2vqLvUC8i09erGuku0JFiT"
                            name={businessName}
                            description="Online Vouchers Payment"
                            image={logoUrl}
                            ComponentClass="div"
                            panelLabel="Pay"
                            amount={recipients.reduce((prev, cur) => prev + Number(cur.voucherPrice), 0) * 100}
                            currency={checkoutCurrency}
                            email={fromEmail}
                            allowRememberMe={false}
                          >
                            <Button variant="contained" color="primary" disabled={submitting ? true : false}>
                              {submitting ? <CircularProgress /> : "Pay With Card"}
                            </Button>
                          </StripeCheckout>
                        </div>
                      </div>
                    </div>
                  }
                </CardContent>
              </Card>
            </Grid>
            <Dialog
              open={alertDialogOpen}
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth={false}
            >
              <DialogTitle>
                Alert
              </DialogTitle>
              <DialogContent>
                <div style={{ whiteSpace: 'pre-line' }}>
                  {alertMessage}
                </div>
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={() => setAlertDialogOpen(false)}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )
      }
    }
  }

  const renderRecipients = () => {
    return recipients.map((recipient, index) => {
      return (
        <div key={index} className="col-xs-12 col-md-4 col-lg-4">
          <Card style={{ margin: 10 }} variant="outlined">
            <CardHeader title={"To Recipient " + (index + 1)} />
            <CardContent>
              <FormControl variant="outlined" fullWidth margin="normal" disabled>
                <InputLabel>Voucher Number</InputLabel>
                <OutlinedInput
                  value={'O-' + (currentVoucherNumber + index + 1).toString()}
                  labelWidth={110}
                  startAdornment={
                    <InputAdornment position="start">
                      <LoyaltyIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel>Name</InputLabel>
                <OutlinedInput
                  value={recipient.toName}
                  onChange={(e) => handleChangeToName(index, e)}
                  labelWidth={50}
                  startAdornment={
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel>Email address</InputLabel>
                <OutlinedInput
                  value={recipient.toEmail}
                  onChange={(e) => handleChangeToEmail(index, e)}
                  labelWidth={110}
                  startAdornment={
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  }
                />
                <FormHelperText>Leave blank to send voucher to your email address.</FormHelperText>
              </FormControl>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel>Message</InputLabel>
                <OutlinedInput
                  value={recipient.message}
                  onChange={(e) => handleChangeMessage(index, e)}
                  labelWidth={70}
                  startAdornment={
                    <InputAdornment position="start">
                      <MessageIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel>Select Voucher</InputLabel>
                <Select
                  value={recipient.selectedVoucherId}
                  onChange={(e) => handleChangeSelectedVoucher(index, e)}
                  input={
                    <OutlinedInput
                      labelWidth={110}
                      startAdornment={
                        <InputAdornment position="start">
                          <CardGiftcardIcon />
                        </InputAdornment>
                      }
                    />
                  }
                >
                  {allowCustomVouchers ?
                    <MenuItem value="custom">
                      <em>Choose my own voucher value.</em>
                    </MenuItem>
                    :
                    null
                  }
                  {allowPresetVouchers ?
                    renderPresetVouchers()
                    :
                    null
                  }
                </Select>
              </FormControl>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel>Voucher Value</InputLabel>
                <OutlinedInput
                  type="number"
                  value={recipient.voucherValue}
                  disabled={recipient.selectedVoucherId === 'custom' ? false : true}
                  onChange={(e) => handleChangeVoucherValue(index, e)}
                  inputProps={{ min: minimumValue, max: maximumValue }}
                  labelWidth={105}
                  startAdornment={
                    <InputAdornment position="start">
                      {currency}
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel>Purchase Price</InputLabel>
                <OutlinedInput
                  value={recipient.voucherPrice}
                  disabled
                  labelWidth={110}
                  startAdornment={
                    <InputAdornment position="start">
                      {currency}
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel>Expiry Date</InputLabel>
                <OutlinedInput
                  value={recipient.voucherExpires}
                  disabled
                  labelWidth={100}
                  startAdornment={
                    <InputAdornment position="start">
                      <EventBusyIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <div className="center-xs">
                <Button color="secondary" onClick={() => handleRemoveRecipient(index)} disabled={index === 0 ? true : false}>
                  Remove Recipient
								</Button>
              </div>
            </CardContent>
          </Card>
        </div>
      )
    });
  }

  const renderPresetVouchers = () => {
    return presetVouchers.map((voucher, index) => {
      return (
        <MenuItem key={index} value={voucher._id}>
          {voucher.description} --- {currency}{(voucher.price / 100).toFixed(2)}
        </MenuItem>
      )
    })
  }

  const renderRecipientsSummary = () => {
    return recipients.map((recipient, index) => {
      return (
        <List key={index} disablePadding>
          <ListItem>
            <ListItemIcon>
              <CardGiftcardIcon />
            </ListItemIcon>
            <ListItemText
              primary={recipient.toName}
              secondary={recipient.toEmail}
            />
            {currency}{Number(recipient.voucherPrice).toFixed(2)}
          </ListItem>
          <ListItem>
            <ListItemText
              inset
              primary="Voucher Details"
              secondary={'Value: ' + currency + Number(recipient.voucherValue).toFixed(2) + ' / Expires: ' + recipient.voucherExpires}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              inset
              primary="Message"
              secondary={recipient.message}
            />
          </ListItem>
        </List>
      )
    })
  }

  return (
    <DocumentMeta {...{ title: businessName === '' ? 'Client Diary' : businessName }}>
      { loading ?
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <CircularProgress />
        </div>
        :
        <div className={classes.root}>
          <div style={getComputedStyle()}>
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h6" className={classes.title}>
                  {businessName}
                </Typography>
              </Toolbar>
            </AppBar>
            <div className="container">
              <Grid container direction="column" alignItems="center">
                { bannerImage === '' ?
                  null
                  :
                  <Grid item>
                    <img src={bannerImage} style={{ maxWidth: '100%', maxHeight: '100%' }} alt="banner" />
                  </Grid>
                }
                {renderContent()}
              </Grid>
            </div>
          </div>
          <div style={{ backgroundColor: 'rgb(56, 67, 76)' }}>
            <div className="container">
              <div className="center-xs" style={{ margin: 10 }}>
                <div style={{ color: 'white', fontSize: 13 }}>
                  Online Voucher powered by <a href="https://clientdiary.com" target="_blank" rel="noreferrer" style={{ color: 'white' }}><b><i>Client Diary</i></b></a>
                </div>
                <div style={{ color: 'white', fontSize: 11, display: (getAuthor() === '' ? 'none' : '') }}>
                  Background image by {getAuthor()} from all-free-download.com
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </DocumentMeta>
  );
}

export default App;
